<template>
    <button
        type="button"
        class="btn flex flex-col w-full items-center text-base text-text border border-border"
        @click="emit('retryClick')"
    >
        <span v-t="'something went wrong!'" class="capitalize" />
        <div class="flex gap-x-1 mt-3 text-white">
            <i class="tg-rr-refresh" />
            <span class="capitalize">{{ $t("retry") }}</span>
        </div>
        <slot />
    </button>
</template>

<script setup lang="ts">
// name: MRetryError

interface IntRetryErrorProps {
    label?: string
}
type IntRetryErrorEmits = (event: "retryClick") => void
withDefaults(defineProps<IntRetryErrorProps>(), {
    label: "",
});
const emit = defineEmits<IntRetryErrorEmits>();

</script>